import container from '@di'
import { useAppStore, useSessionStore, useAnnouncementStore } from '@/stores'
import { useTranslation } from 'i18next-vue'

export default class ConnectionFailedProcedure {
  constructor () {
    const { t } = useTranslation()
    this.name = 'conectionFailedProcedure'
    this.store = useAppStore()
    this.session = useSessionStore()
    this.t = t
  }

  install = () => {
    container.messenger.subscribe(this.name, 'strategy:failed', this.handler)
  }
  
  uninstall = () => {
    container.messenger.unsubscribe(this.name)
  }

  handler = () => {
    const announcement = useAnnouncementStore()
    announcement.showMessage(this.t('announcement.connectionFailed'), 'disconnect')
    this.session.sessionFailed = true
    setTimeout(() => {
      window.location.reload()
      container.procedures.get('destroyProcedure').silentlyDestroy()
    }, 5000)
  }

}
