import { ref, computed } from 'vue'
import { defineStore } from 'pinia'

export default defineStore('announcement', () => {
  const text = ref('')
  const icon = ref(null)
  const hasMessage = computed(() => {
    return text.value.length > 0
  })
  
  function showMessage (text, icon = null) {
    this.$patch({ text, icon })
  }

  function hideMessage () {
    this.$patch({ text: '', icon: null })
  }

  return { 
    text,
    icon,
    hasMessage,
    showMessage,
    hideMessage
  }
})
