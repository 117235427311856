<template>
  <a-announcement
    v-if="announcement.hasMessage"
    :message="announcement.text"
    :icon="announcement.icon"
  />
</template>

<script setup>
import { useAnnouncementStore } from '@/stores'
const announcement = useAnnouncementStore()
</script>
