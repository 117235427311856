import { useUserStore, useSessionStore } from '@/stores'
import { useAppMachine } from '@/state'
import { useTranslation } from 'i18next-vue'

export default class DestroyProcedure {
  constructor () {
    const { state } = useAppMachine()
    const { t } = useTranslation()
    this.name = 'destroyProcedure'
    this.state = state
    this.t = t
  }

  install = () => {
    this.addVisibilityChangeListener()
    this.addBeforeUnloadListener()
  }
  
  addVisibilityChangeListener () {
    const user = useUserStore()
    document.addEventListener('visibilitychange', () => {
      user.setPresence(document.visibilityState === 'visible')
    })
  }

  addBeforeUnloadListener () {
    const session = useSessionStore()
    window.addEventListener('beforeunload', (event) => {
      if (this.state.value.matches('app.view.meeting') && !session.sessionFailed) {
        event.preventDefault()
        event.returnValue = this.t('app.unloadConfirm')
      }
    })
  }

  uninstall = () => {}

}
